footer {
    position: relative;
    padding: 40px 0;
    background-color: $white;

    .dark & {
        background-color: $color-004;
    }

    >div:has(svg) {
        position: absolute;
        bottom: 95%;
        left: 0;
        right: 0;
    }

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        position: relative;
        
        @include min($lg) {
            flex-direction: row;
            justify-content: space-between;
            gap: 0;
        }
        > a {
            font-family: $font-primary;
            font-size: $font-xl;
            color: $color-002;
            transition: all 0.3s ease-in-out;
            &:hover {
                color: $color-002--hover;
            }
            .dark & {
                color: $color-001;
                &:hover {
                    color: $color-002--hover;
                }
            }
        }

        .medium-column {
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                color: $color-002;
                font-size: $font-md;
                transition: color 0.3s ease-in-out;
                &:hover {
                    color: $color-002--hover;
                }

                .dark & {
                    color: $color-001;
                    &:hover {
                        color: $color-002--hover;
                    }
                }
            }
        }

        .social-medias {
            display: flex;
            align-items: center;
            gap: 10px;

            a:hover svg path {
                fill: $color-002--hover;
            }

            svg {
                width: 30px;
                path {
                    fill: $color-002;
                    transition: fill 0.3s ease-in-out;
                    .dark & {
                        fill: $color-001;
                    }
                }
            }
        }
    }
}