.btn {
    border-radius: 50px;
    border: none;
    padding: 10px 50px;
    font-size: $font-sm;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-family: $font-secondary--bold;
    width: fit-content;
    display: block;

    @include min($lg) {
        padding: 15px 50px;
        font-size: $font-md;
    }

    &--primary {
        background-color: $color-002;
        color: $color-001;

        &:hover {
            background: $color-002--hover;
        }

        .dark & {
            background-color: $color-001;
            color: $color-002;

            &:hover {
                background-color: $color-003;
            }
        }
    }
    &--secondary {
        background-color: $color-003;
        color: $color-002;

        &:hover {
            background: $color-002;
            color: $color-003;
        }

        .dark & {
            &:hover {
                background-color: $color-001;
                color: $color-002;
            }
        }
    }

    &--close {
        border: none;
        background-color: transparent;
        font-size: $font-lg;
        font-family: $font-primary;
        cursor: pointer;
    }
}