.modal {
    position: fixed;
    inset: 0 0 0 0;
    background-color: rgba($color: $color-001, $alpha: 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .dark & {
        background-color: rgba($color: $color-004, $alpha: 0.5);
    }

    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: opacity 0.3s;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 0;
        margin-top: 5px;
        flex-wrap: wrap;

        li {
            font-size: $font-sm;
            font-family: $font-secondary--bold;
            background-color: $color-001;
            color: $color-002;
            border-radius: 50px;
            padding: 5px 10px;
        }
    }

    .overlay {
        background-color: $white;
        position: relative;
        border-radius: 30px;
        padding: 10px 10px 10px 10px;
        display: flex;
        gap: 50px;
        justify-content: flex-start;
        max-width: 90%;
        flex-direction: column;
        overflow-y: auto;
        max-height: 90vh;

        @include min($lg) {
            flex-direction: row;
            align-items: center;
            padding: 10px 50px 10px 10px;
            overflow-y: visible;
        }

        .dark & {
            background-color: $color-002;
        }

        img {
            border-radius: 20px;
            max-height: 80vh;
            width: 100%;
            
            @include min($lg) {
                max-width: 60%;
            }
        }

        button {
            position: absolute;
            right: 0px;
            top: -40px;
            @include min($lg) {
                right: 20px;
                top: 20px;
            }

            &:hover svg path {
                fill: $color-002--hover;

                .dark & {
                    fill: $color-003;
                }
            }

            svg {
                width: 20px;

                path {
                    fill: $color-002;
                    transition: fill 0.3s ease-in-out;

                    .dark & {
                        fill: $color-001;
                    }
                }
            }
        }

        a.btn {
            width: 100%;
            text-align: center;
            @include min($lg) {
                width: fit-content;
            }
        }

        p {
            margin-top: 20px;
        }

        a {
            margin-top: 30px;
        }
    }
}