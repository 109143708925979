.fixed-title-section {
    margin-top: 50px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    @include min($md) {
        flex-direction: row;
    }

    >div {
        
        @include min($md) {
            width: 50%;
        }

        &:has(h3) {
            h3 {
                position: sticky;
                width: fit-content;
                top: 20px;

                &::after {
                    @include min($xl) {
                        content: url('../../images/icons/arrow.png');
                        position: absolute;
                        right: -40px;
                        top: 5px;

                        .dark & {
                            content: url('../../images/icons/arrow-dark.png');
                        }
                    }
                }
            }
        }
    }
}