.contact {
    background-color: $color-001;
    margin-top: 50px;
    position: relative;
    padding-top: 200px;
    background-image: url('../../images/contact-grid.webp');
    background-size: cover;
    padding-bottom: 200px;

    @include min($lg) {
        margin-top: 100px;
        padding-top: 200px;
    }

    .dark & {
        background-color: $color-002;
        background-image: url('../../images/contact-grid-dark.webp');
    }

    >div:has(svg) {
        position: absolute;
        top: -1%;
        left: 0;
        right: 0;
        transform: rotate(180deg);
    }

    .contact-row {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: column;

        @include min($lg) {
            flex-direction: row;
            gap: 100px;
            margin-top: 110px;
        }
    }

    img {
        @include min($lg) {
            width: 50%;
        }
    }
}