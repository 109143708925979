.about {
    background-color: $color-001;
    padding: 150px 0;
    background-image: url('../../images/about-grid.webp');
    background-size: cover;
    position: relative;
    margin-top: 10px;

    @include min($lg) {
        padding: 200px 0;
    }

    .dark & {
        background-color: $color-002;
        background-image: url('../../images/about-grid-dark.webp');
    }

    >div {
        &:nth-child(1) {
            position: absolute;
            top: -1%;
            left: 0;
            right: 0;
            transform: rotate(180deg);
        }

        &:last-child {
            position: absolute;
            bottom: -1%;
            left: 0;
            right: 0;
        }
    }

    img {
        max-width: 50%;
    }
}