.bento {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    grid-template-areas: 
         "a a"
         "j i"
         "j c"
         "g b"
         "d b"
         "f e"
         "f e"
         "h h";

    @include min($md) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 20%);
        grid-template-areas: 
        "a a j i"
        "e d j f"
        "e b g f"
        "c b h h";
    }
    
    article {
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        opacity: 0;
        transition: box-shadow 0.3s ease-in-out;
        @include min($md) {
            border-radius: 20px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include min($lg) {
                filter: grayscale(1);
                transition: filter 0.3s ease-in-out;
            }
        }

        &:hover {
            box-shadow: 2px 2px 5px rgba($color-002, 0.6);
            img {
                filter: none;
            }
        }

        &:nth-child(1) {
            // grid-column: 1/3;
            grid-area: a;
        }
        &:nth-child(2) {
            // grid-column: 1;
            // grid-row: 2/4;
            grid-area: b;
        }
        &:nth-child(3) {
            // grid-column: 1;
            // grid-row: 4;
            grid-area: c;
        }
        &:nth-child(4) {
            // grid-column: 2;
            // grid-row: 2;
            grid-area: d;
        }
        &:nth-child(5) {
            // grid-column: 2;
            // grid-row: 3/5;
            grid-area: e;
        }
        &:nth-child(6) {
            // grid-column: 3;
            // grid-row: 1/3;
            grid-area: f;
        }
        &:nth-child(7) {
            // grid-column: 3;
            // grid-row: 3;
            grid-area: g;
        }
        &:nth-child(8) {
            // grid-column: 3/5;
            // grid-row: 4;
            grid-area: h;
        }
        &:nth-child(9) {
            // grid-column: 4;
            // grid-row: 1;
            grid-area: i;
        }
        &:nth-child(10) {
            // grid-column: 4;
            // grid-row: 2/4;
            grid-area: j;
        }
    }
}