.imgwithtext {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
    position: relative;
    align-items: center;

    @include min($lg) {
        flex-direction: row;
        gap: 100px;
        margin-top: 50px;
    }
    &:nth-of-type(1) {
        margin-top: 50px;
        @include min($lg) {
            margin-top: 110px;
        }
    }

    .about & {
        margin-top: 0;
    }

    h3 {
        margin-bottom: 20px;
    }
    ul {
        li {
            font-size: $font-sm;
            position: relative;
            @include min($lg) {
                font-size: $font-md; 
            }
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
            &::before {
                content: url('../../images/icons/arrow.png');
                position: absolute;
                left: -40px;
                top: 0;

                .dark & {
                    content: url('../../images/icons/arrow-dark.png');
                }
            }
        }
    }

    img {
        max-width: 80%;
        @include min($lg) {
            max-width: 50%;
        }
    }

    &.reverse {
        
        @include min($lg) {
            flex-direction: row-reverse;
        }
    }
}