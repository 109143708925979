.resume-post {
  border: 1px solid $color-002;
  border-radius: 20px;
  padding: 10px 10px 60px;
  position: relative;

  .img-wrapper {
    aspect-ratio: 4/3;
    width: 100%;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-size: $font-lg;
    margin-top: 10px;
  }

  &__content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      font-size: $font-sm;
    }
  }

  &__content + a {
    font-size: $font-md;
    text-align: right;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    color: $color-004;
    position: absolute;
    bottom: 10px;
    right: 30px;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $color-003;
    }

    .dark & {
      color: $color-001;

      &:hover {
        color: $color-003;
      }
    }
  }
}
