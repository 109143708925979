@font-face {
    font-family: 'OPTI Auvant Gothic';
    src: url('../../../fonts/OPTIAuvantGothic/OPTIAuvantGothic-Bold.otf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Regular';
    src: url('../../../fonts/Poppins/Poppins-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('../../../fonts/Poppins/Poppins-Bold.ttf');
    font-display: swap;
}

h1 {
    font-family: $font-primary;
    font-size: $font-xxl;
    color: $color-002;
    text-align: center;

    @include min($lg) {
        font-size: $font-xxxl;
        text-align: left;
    }

    .dark & {
        color: $color-001;
    }
}

h2 {
    font-family: $font-primary;
    font-size: $font-xl;
    color: $color-002;
    margin-bottom: 20px;

    @include min($lg) {
        font-size: $font-xxl;
        margin-bottom: 50px;
    }

    .dark & {
        color: $color-001;
    }
}

h3 {
    font-family: $font-primary;
    font-size: $font-lg;
    color: $color-002;

    @include min($lg) {
        font-size: $font-xl;
    }

    .dark & {
        color: $color-001;
    }
}

p {
    font-size: $font-sm;
    line-height: 150%;
    color: $color-002;

    @include min($lg) {
        font-size: $font-md;
    }

    .dark & {
        color: $color-001;
    }
}

ul li, ol li {
    .dark & {
        color: $color-001;
    }
}

.h2 {
    @extend h2;
}

.h3 {
    @extend h3;
}