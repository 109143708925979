header {
    padding: 170px 0 150px 0;
    background-color: $color-001;
    background-image: url('../../images/header-grid.webp');
    background-size: cover;
    position: relative;
    min-height: 100vh;
    @include min($sm) {
        padding: 150px 0;
    }
    @include min($lg) {
        padding: 320px 0 460px;;
    }

    .dark & {
        background-color: $color-002;
        background-image: url('../../images/header-grid-dark.webp');
    }

    &::before {
        content: '';
        width: 1px;
        height: 320px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: $color-002;
        display: none;
        animation: fadeInTop 1s ease-in-out forwards;
        animation-delay: 2s;
        opacity: 0;
        @include min($lg) {
            display: block;
        }

        .dark & {
            background-color: $color-001;
        }
    }

    span {
        color: $color-003;
    }

    p {
        font-size: $font-xl;
        font-family: $font-secondary--bold;
        margin: 20px 0 50px;
        text-align: center;
        @include min($lg) {
            text-align: left;
            margin: 50px 0 70px;
            font-size: $font-xxlg;
        }
    }

    .btn {
        margin: 0 auto;
        @include min($lg) {
            margin: 0 auto 0 0;
        }
    }

    > div:has(svg) {
        position: absolute;
        bottom: -1%;
        left: 0;
        right: 0;
    }
}