form {
    width: 500px;
    max-width: 90%;

    input[type="submit"] {
        margin: 0 auto;

        @include min($lg) {
            margin: 0 auto 0 0;
        }
    }
}

.form-group {
    margin-bottom: 36px;

    &.error {
        input, textarea, input[type="checkbox"] + label::before {
            border-color: $error !important;
        }
        p {
            color: $error;
            font-size: $font-sm;
        }
    }

    &:has(input[type="text"]),
    &:has(input[type="email"]),
    &:has(textarea) {
        position: relative;

        label {
            font-size: 1.8rem;
            background-color: $color-001;
            padding: 0 12px;
            position: absolute;
            top: 0;
            left: 45px;
            transform: translateY(-50%);
            line-height: 1;
            color: $color-002;

            .dark & {
                background-color: $color-002;
                color: $color-001;
            }
        }

        input,
        textarea {
            background-color: $color-001;
            padding: 10px;
            width: 100%;
            border: 1px solid $color-002;
            border-radius: 10px;
            font-size: 1.8rem;
            color: $color-002;

            .dark & {
                background-color: $color-002;
                border-color: $color-001;
                color: $color-001;
            }
        }

        textarea {
            resize: none;
            height: 300px;
        }
    }

    &:has(textarea) {
        margin-bottom: 20px;
    }

    &:has(input[type="checkbox"]) {
        margin-bottom: 30px;
    }

    &:has(input[type="checkbox"]) {
        input {
            appearance: none;
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        label {
            font-size: 1.6rem;
            color: $color-002;
            position: relative;
            cursor: pointer;

            .dark & {
                color: $color-001;
            }

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                border: 1px solid $color-002;
                border-radius: 5px;
                position: absolute;
                left: -25px;
                top: 2px;
                cursor: pointer;
                background-color: $color-001;
                transition: all 0.3s ease-in-out;
                .dark & {
                    background-color: $color-002;
                    border-color: $color-001;
                }
            }

            &::after {
                content: url('../../images/check.webp');
                position: absolute;
                left: -24px;
                top: 2px;
                opacity: 0;
                transition: all 0.3 ease-in-out;
                .dark & {
                    content: url('../../images/check-dark.webp');
                }
            }
        }

        input[type="checkbox"]:checked {
            &~label {
                &::before {
                    background-color: $color-002;

                    .dark & {
                        background-color: $color-001;
                    }
                }

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}