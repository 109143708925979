.mentions-legales {
    background-color: $color-001;

    .dark & {
        background-color: $color-002;
    }

    section {
        padding: 150px 0 200px;
    }

    h2 {
        margin-top: 50px;
    }

    h3 {
        margin-top: 20px;
    }

    p span {
        color: $color-003;
    }
}