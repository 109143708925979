// COLORS
$color-001: #FFF3E1;
$color-002: #2C2824;
$color-003: #ff9f1c;
$color-004: #121212;
$color-002--hover: #6F655B;
$white: #fff;
$error: #cc0000;

// FONTS
$font-primary: 'OPTI Auvant Gothic';
$font-secondary: 'Poppins Regular';
$font-secondary--bold: 'Poppins Bold';

// FONTS SIZES
$font-sm: 1.6rem;
$font-md: 1.8rem;
$font-lg: 2.1rem;
$font-xl: 2.8rem;
$font-xxl: 4.5rem;
$font-xxlg: 5rem;
$font-xxxl: 8rem;

// SCREEN SIZES
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;